const NotFound = () => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
 <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
        <title>페이지를 찾을 수 없습니다.</title>
        <style type="text/css">
            html, body, div, span, applet, object, iframe,
            h1, h2, h3, h4, h5, h6, p, blockquote, pre,
            a, abbr, acronym, address, big, cite, code,
            del, dfn, em, img, ins, kbd, q, s, samp,
            small, strike, strong, sub, sup, tt, var,
            b, u, i, center,
            dl, dt, dd, ol, ul, li,
            fieldset, form, label, legend,
            table, caption, tbody, tfoot, thead, tr, th, td,
            article, aside, canvas, details, embed,
            figure, figcaption, footer, header, hgroup,
            menu, nav, output, ruby, section, summary,
            time, mark, audio, video {
                margin: 0;
                padding: 0;
                border: 0;
                font-size: 100%;
                font: inherit;
                vertical-align: baseline;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }

            /* HTML5 display-role reset for older browsers */
            article, aside, details, figcaption, figure,
            footer, header, hgroup, menu, nav, section {
                display: block;
            }

            html {
                height: 100%;
            }

            body {
                line-height: 1.5;
                font-family: AppleSDGothicNeo-Regular, 'Malgun Gothic', '맑은 고딕', dotum, '돋움', sans-serif;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
                width:100%;
                justify-content: center;
                align-items: center;
                min-width:0px;
            }

            ol, ul {
                list-style: none;
            }

            blockquote, q {
                quotes: none;
            }

            blockquote:before, blockquote:after,
            q:before, q:after {
                content: '';
                content: none;
            }

            table {
                border-collapse: collapse;
                border-spacing: 0;
            }

            a {
                text-decoration: none;
                color: inherit;
            }

            button {
                padding: 0;
                border: 0;
                background: none;
                cursor: pointer;
            }

            input {
                border-radius: 0;
            }

            #wrap {
                padding: 30px 16px;
                text-align: center;
                margin:auto;
            }

            #wrap dl dt {
                margin-bottom: 12px;
                padding-top: 70px;
                background: url('/application/views/pegasus/__assets/img/icon/plzwait.svg') no-repeat center top;
                background-size: 58px 58px;
                font-size: 16px;
                font-weight: bold;
                color: #6345f6;
            }

            #wrap dl dd {
                margin-bottom: 40px;
                padding: 0 16px;
                font-size: 14px;
                color: #333;
                white-space: pre;
            }

            /* Button */
            .btn {
                display: inline-block;
            }

            .btn.type1 {
                background: #6464e7;
                border: 1px solid #6464e7;
                font-size: 15px;
                font-weight: bold;
                color: #fff;
                text-align: center;
            }

            .btn.type2 {
                background: #fff;
                border: 1px solid #6464e7;
                font-size: 15px;
                font-weight: bold;
                color: #6464e7;
                text-align: center;
            }

            .btn.size1 {
                width: 100%;
                height: 44px;
                
            }

            a.btn.size1 {
                padding-top: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .btn-wrap.col2 {
                display: flex;
            }

            .btn-wrap.col2 .btn {
                flex: 1 1 auto;
            }

            .btn-wrap.col2 .btn + .btn {
                margin-left: 7px;
            }
        </style>
    </head>
    <body>

    <div id="wrap">
        <dl>
            <dt>요청하신 페이지를 찾을 수 없습니다.</dt>
            <dd>오류 관련 문의는 <a href="tel:0316983417">031-698-3417</a>로 연락하시기 바랍니다.\n서비스 이용에 불편을 드려서 죄송합니다.</dd>
        </dl>
        <div class="btn-wrap col2">
            <a href="#" class="btn type2 size1" onclick="history.back()">이전</a>
            <a href="/" class="btn type1 size1">홈으로</a>
        </div>
    </div>

    </body>
`,
      }}
    />
  );
};

export default NotFound;
